import * as React from "react";
import Parser from 'html-react-parser';
import { Link } from "gatsby";

const HomeOurServices = ({ flexibleData }) => {
    const Title = flexibleData.espDevServicesSecTitle;
    const Content = flexibleData.espDevServicesSecDescription;
    return (
        <div className="services-accordn">
            <div className="container">

                <div className="title-part">
                    {Title && <h2>{Parser(Title)} </h2>}
                    <div className="content bold-text">
                        {Content && Parser(Content)}
                    </div>
                </div>

                <div className="list-wrapper accordion-wrap">
                    {flexibleData.espDevServicesAccordianRep.map(services => 
                        <div className="list" key={Math.random()}>
                            <div className="name-title click-ttl">{services.espDevServiceTitle && Parser(services.espDevServiceTitle) }</div>
                            <div className="the-content click-content small-dot">
                                {services.espDevServiceDescription && Parser(services.espDevServiceDescription) }
                                <div className="button-2 medium">
                                    <Link to={services.espDevServiceButtonText.url}>{services.espDevServiceButtonText.title}</Link>
                                </div>
                            </div>
                        </div>
                    )}
                </div>

            </div>
        </div>
    )
}

export default HomeOurServices