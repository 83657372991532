import * as React from "react";
import Parser from 'html-react-parser';
import { StaticImage } from "gatsby-plugin-image";
import { useState } from 'react';
import ModalVideo from 'react-modal-video';

const HomeAboutEspark = ({ flexibleData }) => {
	const Title = flexibleData.espDevAboutSecTitle;
	const Content = flexibleData.espDevAboutSecDesc;
	const VideoURL = flexibleData.espDevAboutSecVideo.url;
	const videoID = VideoURL.split('/');

	const isBrowser = typeof window !== "undefined"
	const [isOpen, setOpen] = useState(false)

	return (
		<div className="about-espl">
			<div className="container">
				<div className="title-part">
					{Title && <h2>{Parser(Title)} </h2>}
					<div className="content bold-text">
						{Content && Parser(Content)}
					</div>
				</div>
				<div className="about-counter inner flex align-center">

					{flexibleData.espDevAboutCounterRep.map((projectCount, index) => {
						if (index === 0) {
							return (
								<div className="left-digit" key={Math.random()}>
									<div className="total blue-text digit">{projectCount.espDevCounterTitle && Parser(projectCount.espDevCounterTitle)}</div>
									{projectCount.espDevCounterText && Parser(projectCount.espDevCounterText)}
								</div>
							);
						}
					})}
					<div className="right-wrapper flex row-wrap">
						{flexibleData.espDevAboutCounterRep.map((projectCount, index) => {
							if (index > 0) {
								return (
									<div key={Math.random()}>
										<div className="blue-text digit">{projectCount.espDevCounterTitle && Parser(projectCount.espDevCounterTitle)}</div>
										{projectCount.espDevCounterText && Parser(projectCount.espDevCounterText)}
									</div>
								);
							}
						})}
					</div>
				</div>

				<div className="popup-video">
					<div className="image">
						<StaticImage className="lazy" src="../../images/pop-bg.jpg" alt="" width={1280} height={400} />
						{isBrowser && <ModalVideo channel='youtube' autoplay="1"  isOpen={isOpen} videoId={videoID[videoID.length - 1]} onClose={() => setOpen(false)} />}
						<a data-fancybox to={VideoURL} onClick={() => setOpen(true)}>
							<StaticImage className="lazy" src="../../images/play.svg" alt="" width={120} height={120} />
						</a>
					</div>
				</div>

				<div className="certificates">
					<div className="list-wrapper flex row-wrap justify-center">
					{flexibleData.espDevAboutSecLogoGallery.map(imageUrl => 
						<div className="list bg-gray flex align-center justify-center" key={Math.random()}><div><img className="lazy" src={imageUrl.sourceUrl} alt=""  /></div></div>
					)}
					</div>
				</div>

			</div>
		</div>

	)
}

export default HomeAboutEspark