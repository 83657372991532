import *  as React from "react"
import  {useEffect}  from "react"


import Header from "./header"
import HomeBanner from "./flexible-sections/home-banner-section";
import HomeTrustBy from "./flexible-sections/home-trust-by";
import HomeAboutEspark from "./flexible-sections/home-about-espark";
import HomeOurServices from "./flexible-sections/home-our-services";
import HomeHireDeveloper from "./flexible-sections/home-hire-developer";
import HomeOurTechnology from "./flexible-sections/home-our-technology";

import Footer from "./footer"
import "./typography.css"
import "./layout.css"

const Layout = ({ queryData ,children}) => {
    const homePageData = queryData.allWpPage.nodes[0].HomePageMeta.homepageFlexibleLayout;
    console.log(homePageData)

    useEffect(() => {
      var acc = document.getElementsByClassName("click-ttl");
      
  
      for (var i = 0; i < acc.length; i++) {
        acc[i].addEventListener("click", function() {
          this.classList.toggle("active");
          var panel = this.nextElementSibling;
          if (panel.style.display === "block") {
            panel.style.display = "none";
          } else {
            panel.style.display = "block";
          }
        });
      }
      var tab = document.getElementsByClassName("tabs-title");
      for (var i = 0; i < tab.length; i++) {
        
        tab[i].addEventListener("click", function() {
          var activeClass = document.querySelectorAll(".active.tabs-title");
          var dataTab = this.getAttribute("data-tab");
          for (var j = 0; j < activeClass.length; j++) {
            activeClass[j].classList.remove('active');
          }
          var activeClass = document.querySelectorAll(".tab-content");
          for (var j = 0; j < activeClass.length; j++) {
            activeClass[j].style.display = "none";
          }
          this.classList.add('active');
        document.getElementById(dataTab).style.display = "block";
      })
    }

  
    
  
    }, [])




    const getSection = section => {
        let flexibleSection = section.fieldGroupName;
        switch (flexibleSection) {
          case "page_Homepagemeta_HomepageFlexibleLayout_EspDevHomePageBannerSection":
            return <HomeBanner flexibleData={section} />
          case "page_Homepagemeta_HomepageFlexibleLayout_EspDevTrustedSection":
            return <HomeTrustBy flexibleData={section} />
          case "page_Homepagemeta_HomepageFlexibleLayout_EspDevAboutSection":
            return <HomeAboutEspark flexibleData={section} />
          case "page_Homepagemeta_HomepageFlexibleLayout_EspDevServicesSection":
            return <HomeOurServices flexibleData={section} />
        //   case "Page_Globaltemplatemeta_CandicePageSections_Home5050ImageContent":
        //     return <HomeHireDeveloper flexibleData={section} />
          case "page_Homepagemeta_HomepageFlexibleLayout_EspDevOurTechSec":
            return <HomeOurTechnology flexibleData={section} />
        //   case "Page_Globaltemplatemeta_CandicePageSections_TestimonialSection":
        //     return <HomeTestimonial flexibleData={section} />
        //   case "Page_Globaltemplatemeta_CandicePageSections_AboutVideoSection":
        //     return <AboutVideo flexibleData={section} />
        //   case "Page_Globaltemplatemeta_CandicePageSections_5050ContentImageSection":
        //     return <AboutLifeCoaching flexibleData={section} />
        //   case "Page_Globaltemplatemeta_CandicePageSections_ContactFormSection":
        //     return <ContactForm flexibleData={section} />
        //   case "Page_Globaltemplatemeta_CandicePageSections_IconWithContent":
        //     return <IconWithContent flexibleData={section} />
        //   case "Page_Globaltemplatemeta_CandicePageSections_FaqsSection":
        //     return <FaqSection flexibleData={section} />
                 
        }
      }
    return (
        <>
          <div className="body-container-wrapper">
            <Header />
            {children}
            {homePageData.map(item => <div key={Math.random()} >{getSection(item)}</div>)}
            <Footer />
          </div>
        </>
      )
}



export default Layout