import * as React from "react"
import { Link, graphql } from "gatsby"
import { useStaticQuery } from "gatsby"
import Parser from 'html-react-parser';

const Footer = ({}) => {

  return(
        <></>
    )
}



export default Footer
