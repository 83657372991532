import * as React from "react"
import { graphql} from "gatsby";


import Layout from "../components/layout"
// import Seo from "../components/seo"
const IndexPage = data => (
  <Layout queryData={data.data} >
    {/* <Seo title={data.data.allWpPage.nodes[0].seo.title} description={data.data.allWpPage.nodes[0].seo.metaDesc}/> */}
  </Layout>
)

export default IndexPage

export const createQuery = graphql`
query MyQuery {
  allWpPage(filter: {uri: {eq: "/"}}) {
    nodes {
      title
      uri
      slug
      HomePageMeta {
        homepageFlexibleLayout {
          ... on WpPage_Homepagemeta_HomepageFlexibleLayout_EspDevHomePageBannerSection {
            espDevHomeBannerSecDescription
            espDevHomeBannerSecTitle
            espDevCta {
              title
              url
            }
            espDevHomeBannerTechRep {
              espDevTechnologyDescription
              espDevTechnologyName
              fieldGroupName
            }
            fieldGroupName
          }
          ... on WpPage_Homepagemeta_HomepageFlexibleLayout_EspDevTrustedSection {
            espDevTrustedSecTitle
            espDevTrustedSecLogoGallery {
              sourceUrl
            }
            fieldGroupName
          }
          ... on WpPage_Homepagemeta_HomepageFlexibleLayout_EspDevAboutSection {
            espDevAboutSecDesc
            espDevAboutSecTitle
            espDevAboutCounterRep {
              espDevCounterText
              espDevCounterTitle
            }
            espDevAboutSecVideo {
              url
            }
            espDevAboutSecLogoGallery {
              sourceUrl
            }
            fieldGroupName
          }
          ... on WpPage_Homepagemeta_HomepageFlexibleLayout_EspDevServicesSection {
            espDevServicesSecDescription
            espDevServicesSecTitle
            espDevServicesAccordianRep {
              espDevServiceTitle
              espDevServiceDescription
              espDevServiceButtonText {
                title
                url
              }
            }
            fieldGroupName
          }
          ... on WpPage_Homepagemeta_HomepageFlexibleLayout_EspDevOurProjectsSection {
            espDevDemoProjectDesc
            espDevDemoProjectTitle
            fieldGroupName
          }
          ... on WpPage_Homepagemeta_HomepageFlexibleLayout_EspDevOurTechSec {
            espDevTechSecDesc
            espDevTechSecTitle
            espDevMainTechRepeater {
              espDevTechTabName
              espDevSubTechRepeater {
                espDevSubTechName
                espDevSubTechLogoRep {
                  espDevTechLogoName
                  espDevSubTechLogo {
                    sourceUrl
                  }
                }
              }
            }
            fieldGroupName
          }
          ... on WpPage_Homepagemeta_HomepageFlexibleLayout_EspDevIndustriesSection {
            espDevEduCardDescription
            espDevEduCardTitle
            fieldGroupName
          }
          ... on WpPage_Homepagemeta_HomepageFlexibleLayout_EspDevContactUsSection {
            espDevContactUsDesc
            espDevContactUsTitle
            fieldGroupName
          }
          ... on WpPage_Homepagemeta_HomepageFlexibleLayout_EspDevClientSection {
            espDevClientDesc
            espDevClientTitle
            fieldGroupName
          }
          ... on WpPage_Homepagemeta_HomepageFlexibleLayout_FeathersSection {
            espDevFeathersDesc
            espDevFeathersTitle
            fieldGroupName
          }
          ... on WpPage_Homepagemeta_HomepageFlexibleLayout_EspDevChooseEsparkbizSection {
            espDevChooseEspTitle
            fieldGroupName
          }
          ... on WpPage_Homepagemeta_HomepageFlexibleLayout_EspDevResourcesSection {
            espDevResourcesDesc
            espDevResourcesTitle
            fieldGroupName
          }
          ... on WpPage_Homepagemeta_HomepageFlexibleLayout_EspDevGrowingBusinessSection {
            espDevGrowingDescription
            espDevGrowingFormShortcode
            fieldGroupName
          }
        }
      }
    }
  }
}
`
