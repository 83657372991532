import * as React from "react"
import { Link, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { useStaticQuery } from "gatsby"
import Parser from 'html-react-parser';

const Header = ({}) => {
  return (
    <></>
  )
}
export default Header