import * as React from "react"
import Parser from 'html-react-parser';

const HomeTrustBy = ({ flexibleData }) => {
    const heroTitle = flexibleData.espDevTrustedSecTitle;
    return (

        <div className="trusted-by">
		<div className="inner flex align-center justify-center">
			<div className="text">{heroTitle && Parser(heroTitle) }</div>
			<div className="logo-wrapper flex row-wrap align-center space-between">
            {flexibleData.espDevTrustedSecLogoGallery.map(imageUrl => (
				<div key={Math.random()}><img className="lazy" src={imageUrl.sourceUrl} alt="" width="153" height="65"/></div>
            ))}
                
			</div>
		</div>
	</div>
        
    )
}

export default HomeTrustBy