import * as React from "react";
import { Link } from "gatsby";
import Parser from 'html-react-parser';

const HomeBanner = ({ flexibleData }) => {
    const heroTitle = flexibleData.espDevHomeBannerSecTitle;
    const heroContent = flexibleData.espDevHomeBannerSecDescription;
    const heroCtaTitle = flexibleData.espDevCta.title;
    const heroCtaURL = flexibleData.espDevCta.title;
    return (
        <div className="banner-home">
            <div className="container">
                {heroTitle && <h1>{Parser(heroTitle)} </h1>}
                <div className="content">{heroContent && Parser(heroContent)}</div>
                <div className="list-wrapper flex">
                    {flexibleData.espDevHomeBannerTechRep.map((technology,index) => (
                        <div className="list" key={Math.random()}>
                            {technology.espDevTechnologyName && <h4 className={`${index === 0 ? "yellow-text" : index === 1 ? "green-text" : "blue-text"}`} id={index}>{Parser(technology.espDevTechnologyName)} </h4>}
                            {technology.espDevTechnologyDescription && Parser(technology.espDevTechnologyDescription) }
                        </div>
                    ))}
                </div>
                <div className="button-1">
                    <Link to={heroCtaURL}>{heroCtaTitle && Parser(heroCtaTitle)}</Link>
                </div>
            </div>
        </div>
    )
}

export default HomeBanner