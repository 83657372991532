import React from "react";
import Parser from 'html-react-parser';

const HomeOurTechnology = ({ flexibleData }) => {
    const Title = flexibleData.espDevTechSecTitle;
    const Content = flexibleData.espDevTechSecDesc;
    
    return (
        <div  className="techno-stack">
            <div  className="container">
                <div  className="title-part">
                    {Title && <h2>{Parser(Title)} </h2>}
                    <div  className="content bold-text">
                        {Content && Parser(Content)}
                    </div>
                </div>

                <div  className="tab-wrapper tab-theme-1">
                    <div  className="tabs flex space-between">
                        {flexibleData.espDevMainTechRepeater.map((development, index) =>                    
                            <div  className={`${index == 0 ? "tabs-title active"  : "tabs-title"}`} data-tab={`tab${index}`} key={Math.random()} >{development.espDevTechTabName && Parser(development.espDevTechTabName)}</div>
                        )}
                    </div>
                    {flexibleData.espDevMainTechRepeater.map((development, index) =>
                        
                        <div  className="tab-content" id={`tab${index}`} key={index} style={index == 0 ? {display:"block"} :{display:"none"}}>
                            <div  className="content">
                                <div  className="list-wrapper flex row-wrap">
                                    {development.espDevSubTechRepeater.map(subStack =>
                                        <div  className="list" key={Math.random()}>
                                            <h4>{subStack.espDevSubTechName && Parser(subStack.espDevSubTechName)}</h4>
                                            <div  className="repeat flex row-wrap text-center">
                                                {subStack.espDevSubTechLogoRep.map(technology =>
                                                    <div key={Math.random()}>
                                                        <div  className="icon flex justify-center"><img  className="lazy" src={technology.espDevSubTechLogo.sourceUrl} alt="" width="42" height="45" /></div>
                                                        <div  className="tech">{technology.espDevTechLogoName && Parser(technology.espDevTechLogoName)}</div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )}

                                </div>
                            </div>
                        </div>
                    )}
                </div>

            </div>
        </div>
    )
}

export default HomeOurTechnology;